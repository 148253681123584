/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Accounttate {
    total_members: number;
    total_accounts: number;
    top_affiliates: any;
    current_inlane_page: number;
    current_monoline_ctr: number;
    members: any;
    genealogy: any;
    monoline_account: any;
    account: {
        details: any;
        earnings: any;
        incentives: any;
    };
}

const initialState: Accounttate = {
    total_members: 0,
    total_accounts: 0,
    current_inlane_page: 0,
    current_monoline_ctr: 0,
    top_affiliates: null,
    members: null,
    genealogy: null,
    monoline_account: null,
    account: {
        details: null,
        earnings: null,
        incentives: null,
    },
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        saveAccount(
            state,
            action: PayloadAction<{
                data: {
                    total_members: number;
                    total_accounts: number;
                    current_inlane_page: number;
                    current_monoline_ctr: number;
                    top_affiliates: any;
                    monoline_account: any;
                    members: any;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_members = data.total_members;
            state.total_accounts = data.total_accounts;
            state.top_affiliates = data.top_affiliates;
            state.monoline_account = data.monoline_account;
            state.current_inlane_page = data.current_inlane_page;
            state.current_monoline_ctr = data.current_monoline_ctr;
            state.members = data.members;
        },
        fetchTopAffiliates(
            state,
            action: PayloadAction<{
                top_affiliates: any;
            }>
        ) {
            const { payload } = action;
            state.top_affiliates = payload.top_affiliates;
        },
        fetchMembers(
            state,
            action: PayloadAction<{
                members: any;
            }>
        ) {
            const { payload } = action;
            state.members = payload.members;
        },
        saveMembersAccounts(
            state,
            action: PayloadAction<{
                monoline_account: any;
            }>
        ) {
            const { payload } = action;
            state.monoline_account = payload.monoline_account;
        },
        setGenealogy(
            state,
            action: PayloadAction<{
                data: any;
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.genealogy = data.genealogy;
            state.account.details = data.account.details;
            state.account.earnings = data.account.earnings;
            state.account.incentives = data.account.incentives;
        },
    },
});

export const {
    saveAccount,
    fetchTopAffiliates,
    fetchMembers,
    setGenealogy,
    saveMembersAccounts,
} = accountSlice.actions;
export default accountSlice.reducer;
